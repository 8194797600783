<template>
  <div>
    <div v-if="qrCode" v-html="qrCode"></div>
  </div>
</template>

<script>
import useStudentFinancialTransactions from "@/composables/student_financial_transactions";
import { inject, ref, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const hasAnyRole = inject("hasAnyRole");
    const student_id = ref(null);
    const qrCode = ref(null);
    const { geteWalletQrCode } = useStudentFinancialTransactions();

    onBeforeMount(async () => {
      //first: check the user Permission
      if (!hasAnyRole("Student,Parent")) {
        router.push({ name: "401" });
      } else {
        if (typeof route.query.student_id !== "undefined") {
          student_id.value = route.query.student_id;
        }
        qrCode.value = await geteWalletQrCode(student_id.value);
      }
    });

    return {
      qrCode,
    };
  },
};
</script>
